<template>
  <div class="page">
    <div class="content flex-wrap flex items-start">
      <div class="bg-white p-30px flex flex-col items-center" style="flex: 3">
        <!-- 标题 -->
        <div class="text-28px font-semibold">2023梅里100极限耐力赛</div>
        <!-- 时间 -->
        <div class="text-16px mt-15px" style="color: #777777">2022.11.25 12:23:12</div>
        <!-- 图片 -->
        <div class="flex flex-wrap justify-between items-start mt-11px">
          <img
            v-for="(item, index) in 6"
            :key="index"
            class="w-300px h-220px mt-24px rounded-3px"
            src="https://tx-match.oss-cn-shanghai.aliyuncs.com/plat/carousel/2023/3/29/16_56_20_437.jpg"
            alt=""
          />
        </div>
        <div class="pagination relative w-full">
          <el-pagination
            @current-change="currentChange"
            :page-size="15"
            background
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
          <div class="absolute right-0 text-14px font-semibold">（来源：江苏省田径协会）</div>
        </div>

        <div class="border-b border-gray-300 mt-30px w-full"></div>
        <div class="ml-14px mt-34px w-full">
          <div class="text-14px text-gray-300 cursor-pointer">上一篇：江苏田协会召开全国路跑赛事</div>
          <div class="text-14px mt-13px cursor-pointer">下一篇：报名开启！2022江苏半程马拉松定档及相关规定</div>
        </div>
      </div>
      <!-- 右边 -->
      <div class="ml-10px bg-white px-24px py-30px" style="flex: 1">
        <div class="flex">
          <div class="h-14px" style="border: 3px solid #e50114"></div>
          <div class="text-24px font-semibold ml-9px">相关相册</div>
        </div>
        <div class="mt-12px overflow-hidden">
          <div class="mt-20px flex cursor-pointer pictureText" v-for="(item, index) in 4" :key="index">
            <div>
              <img class="w-128px h-80px rounded-3px" src="@/assets/image/index/newsRight.png" alt="" />
            </div>
            <div class="flex flex-col justify-between items-start h-80px ml-11px">
              <div class="text-16px">2022届江苏田协会召开全国路跑赛事</div>
              <div class="text-16px text-gray-400">2022-12-12</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { newClassify, newsList } from '@/service/api/news'
export default {
  data() {
    return {
      currentIndex: 0,
      nav: [],
      list: [],
      params: {
        class_id: 0,
        page: 1
      },
      total: 0,
      isShow: true
    }
  },
  methods: {
    // 新闻分类
    async newClassify() {
      const res = await newClassify()
      this.nav = res.msg
      this.newsList()
    },
    // 新闻列表
    async newsList() {
      const res = await newsList(this.params)
      this.list = res.msg.data
      this.total = res.msg.total
      this.isShow = false
      // console.log(this.list)
    },
    navClick(index) {
      this.currentIndex = index
      this.params.class_id = index
      this.newsList()
    },
    newsClick(id) {
      this.$router.push({
        path: '/picture-detail',
        query: {
          id
        }
      })
    },
    currentChange(page) {
      this.params.page = page
      this.newsList()
    }
  }
}
</script>
<style scoped lang="less">
.page {
  padding-bottom: 38px;
  //   background-color: #fff;
}
.pagination {
  display: flex;
  margin-top: 29px;

  justify-content: center;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: @color_one;
  color: #fff;
}
.pictureText:hover {
  color: #e6425e !important;
}
</style>
